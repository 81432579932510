import * as React from 'react';

import cn from 'classnames';

import Button from 'ui-kit/button';

import { globalClasses } from 'constants/class-names';

import { ButtonInformation } from './type';

import styles from './auth-buttons.module.scss';

interface AuthButtonsProps {
  buttons: ButtonInformation[];
  disabled?: boolean;
}

const AuthButtons: React.FC<AuthButtonsProps> = ({ buttons, disabled }) => {
  return (
    <div className={cn(globalClasses.auth.buttons, styles.buttons)}>
      {buttons.map(({ text, className, variant, ...restProps }) => {
        const buttonDisabled = disabled && restProps.type === 'submit';

        return (
          <Button
            key={text}
            disabled={buttonDisabled}
            className={cn(styles.button, className)}
            variant={variant}
            {...restProps}>
            {text}
          </Button>
        );
      })}
    </div>
  );
};

export default AuthButtons;
