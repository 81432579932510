import { useTranslation } from 'next-i18next';
import * as React from 'react';

import { UrlObject } from 'url';

import { useCustomRouter } from 'hooks/useCustomRouter.hook';
import useSafePush from 'hooks/useSafePush';

import AuthButtons from 'components/auth-buttons';
import { ButtonInformation } from 'components/auth-buttons/type';

import Title from 'ui-kit/title';

import { projectName } from 'constants/company';

import { Links } from 'types/links';

import styles from './hello.module.scss';

interface HelloProps {}

const Hello: React.FC<HelloProps> = () => {
  const { t } = useTranslation();

  const { pathname } = useCustomRouter();

  const push = useSafePush();

  const handleGo = (url: UrlObject) => () => {
    push(url);
  };

  const buttons: ButtonInformation[] = [
    {
      text: t('onboarding:buttons.registration'),
      onClick: handleGo({
        pathname: Links.registration,
      }),
    },
    {
      text: t('onboarding:buttons.auth'),
      onClick: handleGo({
        pathname: Links.auth,
      }),
    },
    {
      text: t('onboarding:buttons.contacts'),
      variant: 'text',
      onClick: handleGo({
        pathname: Links.contacts,
        query: {
          redirect: pathname,
        },
      }),
    },
  ];

  return (
    <div>
      <div className={styles.text}>
        <Title variant="h2">
          {t('onboarding:title', {
            name: projectName,
          })}
        </Title>

        <Title variant="h3" isNoBold>
          {t('onboarding:description', {
            name: projectName,
          })}
        </Title>
      </div>

      <AuthButtons buttons={buttons} />
    </div>
  );
};

export default Hello;
