import Head from 'next/head';
import * as React from 'react';

import { projectName } from 'constants/company';

import { Nullable } from 'types';

interface PageHeadProps {
  name: string;
  description?: Nullable<string>;
}

const PageHead: React.FC<PageHeadProps> = ({ name, description = '' }) => (
  <Head>
    <title>
      {projectName}. {name}
    </title>

    {description ? <meta name="description" content={description} /> : null}
  </Head>
);

export default PageHead;
