import { NextPage } from 'next';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

import Hello from 'components/hello';
import PageHead from 'components/pageHead';

import { projectName } from 'constants/company';
import { namespaces } from 'constants/language';

const StartPage: NextPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageHead
        name={t('onboarding:title', {
          name: projectName,
        })}
        description={String(
          t('onboarding:head.description', {
            name: projectName,
          }),
        )}
      />

      <Hello />
    </>
  );
};

export async function getStaticProps({ locale = 'ru' }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, namespaces)),
    },
  };
}

export default StartPage;
